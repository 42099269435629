import React, { useEffect, useState } from "react"
import Layout from "components/common/layout.js"
import bigDot from "images/bigDot.png"
import CheckBox from "components/tools/checkBox.js"
import * as styles from "pages/member/setting/unsubscribe.module.css"
import SettingLayout from "components/member/Setting/settingLayout"
import UnsubscribeCheckBoxContainer from "containers/member/setting/unsubscribeCheckBoxContainer"
import api from "apis"
import { getIsScb } from "utils"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { setProductListAsync } from "store/modules/payInfoReducer.js"
import { navigate } from "gatsby"
const UnsubscribePlanPage = props => {
  const [scbInfo, setScbInfo] = useState({})
  const dispatch = useDispatch()
  useEffect(() => {
    ;(async () => {
      const scbInfo = await api.getscbDetail()
      setScbInfo(scbInfo)
      await errorHandler(scbInfo)

      dispatch(setProductListAsync())
    })()
  }, [])

  const { list } = useSelector(
    ({ payInfoReducer: { product } }) => product,
    shallowEqual
  )

  const goods = list.get(scbInfo.goods_no)

  return (
    <Layout settingHeader headerStyle={{ display: "none" }}>
      <SettingLayout title="구독해지" />

      <div className={styles.con}>
        <img src={bigDot} alt={"titleLogo"} />
        <p style={{ fontWeight: "900", color: "#333", fontSize: "20px" }}>
          {goods?.title}을 해지하시겠어요?
        </p>
        <p style={{ fontSize: "18px", fontWeight: "normal", color: "#333" }}>
          구독을 해지하시려면 아래의 사항을 확인해주세요.
        </p>

        <div className={styles.greybox}>
          <div className={styles.explain}>
            <div className={styles.doublecolonbox}>
              <p className={styles.doublecolon}>⦁</p>
              <p className={styles.doublecolon}>⦁</p>
            </div>
            <div className={styles.explain}>
              구독 중단 신청일부터 피치서가를 이용할 수 없습니다.
            </div>
          </div>
          <div className={styles.explain}>
            <div className={styles.doublecolonbox}>
              <p className={styles.doublecolon}>⦁</p>
              <p className={styles.doublecolon}>⦁</p>
            </div>
            <div className={styles.explain}>
              환불 정책에 따라 환불 금액이 결정되며, 환불 정책은 아래와
              같습니다.
            </div>
          </div>
          <div style={{ paddingTop: "10px" }}>
            <div style={{ marginLeft: "10px" }}>
              <div className={styles.explaintextbox}>
                <p className={styles.colon}>⦁ </p>
                <span className={styles.explaintext}>
                  환불 시 이용기간에 대해 정가로 측정하여 요금을 부과합니다.
                </span>
              </div>
              <div className={styles.explaintextbox}>
                <p className={styles.colon}>⦁ </p>
                <span className={styles.explaintext}>
                  온라인+매거진 환불 시 이용기간에 대해 정가로 측정하며, 발송한
                  매거진의 배송비를 포함하여 요금을 부과합니다.
                </span>
              </div>
              <div className={styles.explaintextbox}>
                <p className={styles.colon}>⦁ </p>
                <span className={styles.explaintext}>
                  서비스 이용 중인 월에 대해서는 아래와 같이 적용합니다.
                </span>
              </div>

              <div>
                <div className={styles.graph}>
                  <div>
                    <div>이용 시작일</div>
                    <div>10일까지</div>
                    <div>20일까지</div>
                    <div>30일까지</div>
                  </div>
                  <div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
                <div className={styles.detail}>
                  <div>
                    <p>{`이용 시작일로부터 ~ 10일까지 = 결제금액 - {(1개월 정가➗30일) x 10일 + (1개월 정가 x 사용 개월 수)} = 환불 금액`}</p>
                  </div>
                  <div>
                    <p>{`이용 시작일로부터 ~ 10일까지 = 결제금액 - {(1개월 정가➗30일) x 10일 + (1개월 정가 x 사용 개월 수)} = 환불 금액`}</p>
                  </div>
                  <div>
                    <p>{`이용 시작일로부터 ~ 10일까지 = 결제금액 - {(1개월 정가➗30일) x 10일 + (1개월 정가 x 사용 개월 수)} = 환불 금액`}</p>
                  </div>
                </div>
              </div>

              <div className={styles.explaintextbox}>
                <p className={styles.colon}>⦁ </p>
                <span className={styles.explaintext}>
                  구독 시작 후 첫 달 7일 이내 서비스 사용기록이 없다면 환불
                  가능합니다.
                </span>
              </div>
              <div className={styles.explaintextbox}>
                <p className={styles.colon}>⦁ </p>
                <span className={styles.explaintext}>
                  구독 시작 후 첫 달 7일 이내 서비스 사용기록이 없더라도 7일
                  이후 구독을 중단한다면 환불되지 않습니다.
                </span>
              </div>
              <div className={styles.explaintextbox}>
                <p className={styles.colon}>⦁ </p>
                <span className={styles.explaintext}>
                  구독 해지 신청 후 2~3일 내에 결제하신 수단으로 환불 처리가
                  됩니다.
                </span>
              </div>
            </div>
          </div>

          <div>
            <div className={styles.doublecolonbox}>
              <p className={styles.doublecolon}>⦁</p>
              <p className={styles.doublecolon}>⦁</p>
            </div>
            <div className={styles.explain}>
              적용 된 쿠폰에 대해서는 아래와 같이 적용합니다.
            </div>
          </div>
          <div>
            <div style={{ marginLeft: "10px", fontSize: "14px" }}>
              <div className={styles.explaintextbox}>
                <p className={styles.colon}>⦁ </p>
                <span className={styles.explaintext}>
                  환불 시 할인 쿠폰은 적용되지 않으며, 이용기간에 대해 정가로
                  측정하여 요금을 부과합니다.
                </span>
              </div>
              <div className={styles.explaintextbox}>
                <p className={styles.colon}>⦁ </p>
                <span className={styles.explaintext}>
                  기간연장 쿠폰으로 연장된 기간은 적용되지 않습니다.
                </span>
              </div>
              <div className={styles.explaintextbox}>
                <p className={styles.colon}>⦁ </p>
                <span className={styles.explaintext}>
                  무료체험 기간은 적용되지 않습니다.
                </span>
              </div>
            </div>
          </div>
          <div>
            <div className={styles.doublecolonbox}>
              <p className={styles.doublecolon}>⦁</p>
              <p className={styles.doublecolon}>⦁</p>
            </div>
            <div className={styles.explain}>
              나의 서가에 보관한 모든 콘텐츠는 서비스 종료일부터 6개월간
              유지됩니다.
            </div>
          </div>
        </div>

        <div className={styles.confirmbox}>
          <p style={{ fontSize: "14px", fontWeight: "normal" }}>
            <CheckBox id={"CheckUnsubscribe"} className={"circleCheckBox"} />
            &nbsp;구독 해지 사항 및 환불 정책에 대해 확인했습니다.
          </p>
        </div>
        <hr></hr>
      </div>
      <div className={styles.opinionbox}>
        <UnsubscribeCheckBoxContainer
          errorHandler={errorHandler}
          scbInfo={scbInfo}
        />
      </div>
    </Layout>
  )
}

export default UnsubscribePlanPage

const errorHandler = async scbInfo => {
  if (!scbInfo.isScb) {
    if (await confirm("현재 구독중이 아닙니다.")) {
      navigate(-1)
    }
    return true
  } else if (scbInfo.isRefund) {
    if (await confirm("관리자가 이미 환불을 진행중입니다.")) {
      navigate(-1)
    }
    return true
  } else {
    return false
  }
}
